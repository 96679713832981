import React from 'react'
import dynamic from 'next/dynamic'
import { STATIC_PAGES } from '@consts/index'
import { PageProps } from '@components/pages/dynamic'

const DynamicPages = dynamic(() => import('@components/pages/dynamic'))
const StaticPages = dynamic(() => import('@components/pages/static'))

type PagesProps = {
  handle: string
  page: PageProps[]
}

function Pages({ handle, page }: PagesProps) {
  const staticPage = STATIC_PAGES.find(
    staticPage => staticPage.handle === handle
  )

  if (staticPage) {
    return <StaticPages pagesHandle={staticPage.handle.replace('/', '')} />
  }
  return <DynamicPages page={page} handle={handle} />
}

export default Pages
